import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col col-md-3 col-s-1" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "fa-solid fa-user fa-3x",
  style: {"width":"50px","height":"50px","display":"flex","justify-content":"center","align-items":"center"},
  "aria-hidden": "true"
}
const _hoisted_5 = { class: "col-md-7 col-s-11" }
const _hoisted_6 = ["innerHTML"]

import { IndividuPresentation, useIndividuPresentationStore } from "@/store/individu-presentation-store";
import { onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RechercheAuteur',
  setup(__props) {

const individuPresentationStore = useIndividuPresentationStore()
const listeIndividuPresentation = ref<IndividuPresentation[] | null>(null);
onMounted(async () => {
  listeIndividuPresentation.value = await individuPresentationStore.donneListeIndividuPresentationComplete()
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(listeIndividuPresentation.value, (ind) => {
    return (_openBlock(), _createElementBlock("div", {
      key: ind.id,
      class: "card m-md-1 mb-3 limite-hauteur border p-3"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", null, _toDisplayString(ind.pseudonyme), 1),
          (ind.imagePresentation)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: ind.imagePresentation,
                style: {"width":"50px","height":"50px"},
                class: "rounded",
                alt: "Image de profil"
              }, null, 8, _hoisted_3))
            : (_openBlock(), _createElementBlock("i", _hoisted_4))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", {
            class: "card-text",
            innerHTML: ind.presentation
          }, null, 8, _hoisted_6)
        ])
      ])
    ]))
  }), 128))
}
}

})