import { IndividuPresentation } from "@/store/individu-presentation-store";
import { defineStore } from "pinia";
import { ref } from "vue";
import axiosInstance from "@/utils/axios-config";
import { Erreur } from "@/utils/Erreur";


export class GroupeIndividu {
  id: null | number;
  libelle: string;
  listeMembre: IndividuPresentation[];


  constructor(id: number | null, libelle: string, listeMembre: IndividuPresentation[]) {
    this.id = id;
    this.libelle = libelle;
    this.listeMembre = listeMembre;
  }
}

export const useGroupeIndividuStore = defineStore('groupeIndividuStore', () => {
  const listeGroupeIndividu = ref<GroupeIndividu[] | null>(null);
  const erreurRecuperationListe = ref<Erreur | null>(null)
  const rechercherListe = async () => {
    if (listeGroupeIndividu.value == null) {
      try {
        const response = await axiosInstance.get(`/private/groupeindividu/`);
        listeGroupeIndividu.value = response.data;
      } catch (error) {
        erreurRecuperationListe.value = new Erreur("Erreur lors de la récupération de l'individu")
      }
    }
  }
  const creerGroupeIndividu = async (libelle: string) => {
    console.log("creerGroupeIndividu")
    console.log(libelle)
    if (libelle.length != 0 && libelle.length <= 30) {
      console.log("nianainai")
      const gr = new GroupeIndividu(null, libelle, []);
      try {
        const reponse = await axiosInstance.post('/private/groupeindividu/', gr);
        listeGroupeIndividu.value?.push(reponse.data);
      } catch (error: unknown) {
        if (error != null) {
          return new Erreur(error)
        }
      }
    }
    return null;
  }
  const modifierGroupeIndividu = async (gr: GroupeIndividu) => {
    if (gr.libelle.length < 30) {
      try {
        const reponse = await axiosInstance.put('/private/groupeindividu/', gr);
        if (reponse.data === true) {
          const gr2 = listeGroupeIndividu.value?.find((x) => x.id === gr.id)
          if (gr2) gr2.libelle = gr.libelle;
        }
      } catch (error: unknown) {
        if (error != null) {
          return new Erreur(error)
        }
      }
    }
  }
  const supprimerGroupeIndividu = async (gr: GroupeIndividu) => {
    try {
      const reponse = await axiosInstance.delete(`/private/groupe-individu/${gr.id}`);
      if (reponse.data === true && listeGroupeIndividu.value) {
        const index = listeGroupeIndividu.value?.findIndex((x: GroupeIndividu) => x.id === gr.id)
        listeGroupeIndividu.value.splice(index, index)
      }
    } catch (error: unknown) {
      if (error != null) {
        return new Erreur(error)
      }
    }
  }
  const ajouterMembre = async (gr: GroupeIndividu, ind: IndividuPresentation) => {
    try {
      const reponse = await axiosInstance.put(`/private/groupe-individu/membre-ajout/${ind.id}`);
      if (reponse.data === true && listeGroupeIndividu.value) {
        const gr2 = listeGroupeIndividu.value?.find((x) => x.id === gr.id)
        if (gr2) {
          gr2.listeMembre.push(ind);
        }
      }
    } catch (error: unknown) {
      if (error != null) {
        return new Erreur(error)
      }
    }
  }
  const supprimerMembre = async (gr: GroupeIndividu, ind: IndividuPresentation) => {
    try {
      const reponse = await axiosInstance.put(`/private/groupe-individu/membre-ajout/${ind.id}`);
      if (reponse.data === true && listeGroupeIndividu.value) {
        const gr2 = listeGroupeIndividu.value?.find((x) => x.id === gr.id)
        if (gr2) {
          const index = listeGroupeIndividu.value?.findIndex((x: GroupeIndividu) => x.id === gr.id)
          gr2.listeMembre.splice(index, index);
        }
      }
    } catch (error: unknown) {
      if (error != null) {
        return new Erreur(error)
      }
    }
  }
  return {
    creerGroupeIndividu,
    rechercherListe,
    modifierGroupeIndividu,
    supprimerGroupeIndividu,
    ajouterMembre,
    supprimerMembre,
    listeGroupeIndividu
  }
})