<template>
  <div v-for="ind in listeIndividuPresentation" :key="ind.id" class="card m-md-1 mb-3 limite-hauteur border p-3">
    <div class="row">
      <div class="col col-md-3 col-s-1">
      <h5>{{ ind.pseudonyme }}</h5>
      <img v-if="ind.imagePresentation"
           :src="ind.imagePresentation" style="width: 50px; height: 50px;" class=" rounded" alt="Image de profil">
      <i v-else class="fa-solid fa-user fa-3x"
         style="width: 50px; height: 50px; display: flex; justify-content: center; align-items: center;"
         aria-hidden="true"></i>
      </div>
      <div class="col-md-7 col-s-11">
        <p class="card-text" v-html="ind.presentation"></p>
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">
import { IndividuPresentation, useIndividuPresentationStore } from "@/store/individu-presentation-store";
import { onMounted, ref } from "vue";

const individuPresentationStore = useIndividuPresentationStore()
const listeIndividuPresentation = ref<IndividuPresentation[] | null>(null);
onMounted(async () => {
  listeIndividuPresentation.value = await individuPresentationStore.donneListeIndividuPresentationComplete()
})

</script>

<style scoped lang="scss">
.limite-hauteur {
  max-height: 8em; /* Limite de hauteur */
  overflow: hidden; /* Cache tout ce qui dépasse */
}
</style>