import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-s-12 col-md-10 col-xl-8 mx-auto" }
const _hoisted_3 = { class: "card mb-3 card-body" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "row" }
const _hoisted_7 = {
  class: "list-group col-sm-12 col-md-6",
  role: "tablist"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "badge text-bg-primary rounded-pill" }
const _hoisted_10 = {
  key: 0,
  class: "col-sm-12 col-md-6"
}

import { GroupeIndividu, useGroupeIndividuStore } from "@/store/groupe-individu-store";
import { onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'IndividuConfigurationGroupe',
  setup(__props) {


const groupeIndividuStore = useGroupeIndividuStore()
const libelle = ref("");
const isCreationEnabled = ref(true);
const isLibelleValid = ref(false);
const groupeSelectionne = ref<GroupeIndividu|null>(null)

const evaluationValiditeLibelle = () => {
  isLibelleValid.value = libelle.value.length !== 0 && libelle.value.length <= 30;
}
const creerGroupe = async () => {
  isCreationEnabled.value = false
  if (isLibelleValid.value === true) {
    await groupeIndividuStore.creerGroupeIndividu(libelle.value);
    libelle.value = ""
  }
  isCreationEnabled.value = true
}

const selectionner = (gr : GroupeIndividu) =>{
  console.log(gr)
  groupeSelectionne.value = gr;
}

onMounted(async () => {
  await groupeIndividuStore.rechercherListe()
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "card-title" }, "Gestion des groupes de personnes", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "card-text" }, "Les groupes d'individus vous permettront de paramétrer finement les droits de lecture sur vos oeuvres en autorisant l'accès à des oeuvres privées ou en interdisant l'accès à des oeuvres visibles par la communeauté. ", -1)),
        _cache[6] || (_cache[6] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("form", {
          onSubmit: _withModifiers(creerGroupe, ["prevent"]),
          class: "needs-validation"
        }, [
          _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Création de groupes", -1)),
          _createElementVNode("div", _hoisted_4, [
            _cache[1] || (_cache[1] = _createElementVNode("label", { class: "form-label" }, "Libellé", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: _normalizeClass(["form-control", isLibelleValid.value? 'is-valid':'is-invalid']),
              placeholder: "Mes amis",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((libelle).value = $event)),
              onInput: evaluationValiditeLibelle
            }, null, 34), [
              [_vModelText, libelle.value]
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "invalid-feedback" }, " Le libellé ne doit pas dépasser 30 caractères ", -1))
          ]),
          _createElementVNode("button", {
            type: "submit",
            class: "btn btn-primary",
            disabled: !isLibelleValid.value || !isCreationEnabled.value
          }, "Créer ", 8, _hoisted_5)
        ], 32),
        _cache[7] || (_cache[7] = _createElementVNode("hr", null, null, -1)),
        _cache[8] || (_cache[8] = _createElementVNode("h3", null, "Liste des groupes", -1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("ul", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupeIndividuStore).listeGroupeIndividu, (gr) => {
              return (_openBlock(), _createElementBlock("li", {
                key: gr.id,
                class: "list-group-item list-group-item-action d-flex justify-content-between align-items-start",
                "data-bs-toggle": "list",
                onClick: ($event: any) => (selectionner(gr))
              }, [
                _createTextVNode(_toDisplayString(gr.libelle) + " ", 1),
                _createElementVNode("span", _hoisted_9, _toDisplayString(gr.listeMembre.length), 1)
              ], 8, _hoisted_8))
            }), 128))
          ]),
          (groupeSelectionne.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("h4", null, _toDisplayString(groupeSelectionne.value.libelle), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})