<template>
  <div class="card m-3 ">
    <div class="row">
      <div class="col-sm-4 col-md-3 col-xl-2 m-3">
        <img v-if="storeIndividuPresentation.individuSelectionne?.imagePresentation"
             :src="storeIndividuPresentation.individuSelectionne?.imagePresentation" style="width: 250px;" class="img-fluid rounded" alt="Image de profil">
        <i v-else class="fa-solid fa-user fa-3x"  style="width: 250px; height: 250px; display: flex; justify-content: center; align-items: center;" aria-hidden="true"></i>
      </div>
      <div class="col-sm-8 col-md-8 col-xl-8">
        <div class="card-body">
          <h5 class="card-title mb-3" >{{storeIndividuPresentation.individuSelectionne?.pseudonyme}}</h5>
          <hr/>
          <p class="card-text" v-html="storeIndividuPresentation.individuSelectionne?.presentation"></p>
        </div>
      </div>
    </div>

  </div>
  <ul class="nav nav-tabs">
    <li class="nav-item"><router-link class="nav-link"  :to="'/individu/'+id+'/texte/'">Textes</router-link>  </li>
    <li class="nav-item"><router-link class="nav-link"  :to="'/individu/'+id+'/lecture/'">Lectures</router-link>  </li>
    <li class="nav-item"><router-link class="nav-link"  :to="'/individu/'+id+'/atelier/'">Ateliers</router-link>  </li>
    <li class="nav-item"><router-link class="nav-link"  :to="'/individu/'+id+'/discussion/'">Discussions</router-link>  </li>
    <li class="nav-item"><router-link class="nav-link"  :to="'/individu/'+id+'/bibliotheque/'">Bibliothèque</router-link></li>
  </ul>
  <router-view></router-view>
</template>

<style lang="scss">
  .ql-align-right{
    text-align: right;
  }
  .ql-align-left{
    text-align: left;
  }
  .ql-align-center{
    text-align: center;
  }
  .ql-align-justify{
    text-align: justify;
  }
</style>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import { useIndividuPresentationStore } from "@/store/individu-presentation-store";
import { onMounted, ref } from "vue";

const route = useRoute()
const id = Number(route.params.id);

const storeIndividuConnecte = useIndividuConnecteStore()
const storeIndividuPresentation = useIndividuPresentationStore()

const isIndividuConnecte = ref(false)


onMounted(async () => {
  try {
    await storeIndividuPresentation.selectionnerIndividu(id)
    isIndividuConnecte.value = storeIndividuConnecte.individuConnecte?.id === id
  } catch (error) {
    console.log("OnMounted Error");
  }
})
</script>