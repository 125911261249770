<template>
  <div class="row">
    <div class="col-s-12 col-md-10 col-xl-8 mx-auto">
      <div class="card mb-3 card-body">
        <h2 class="card-title">Gestion des groupes de personnes</h2>
        <div class="card-text">Les groupes d'individus vous permettront de paramétrer finement les droits de lecture
          sur vos oeuvres
          en autorisant l'accès à des oeuvres privées ou en interdisant l'accès à des oeuvres visibles par la
          communeauté.
        </div>
        <hr />
        <form @submit.prevent="creerGroupe" class="needs-validation">
          <h3>Création de groupes</h3>
          <div class="form-group">
            <label class="form-label">Libellé</label>
            <input type="text" class="form-control" placeholder="Mes amis" v-model="libelle"
                   :class="isLibelleValid? 'is-valid':'is-invalid'"
                   @input="evaluationValiditeLibelle">
            <div class="invalid-feedback">
              Le libellé ne doit pas dépasser 30 caractères
            </div>
          </div>
          <button type="submit" class="btn btn-primary" :disabled="!isLibelleValid || !isCreationEnabled">Créer
          </button>
        </form>
        <hr />
        <h3>Liste des groupes</h3>
        <div class="row">
          <ul class="list-group col-sm-12 col-md-6" role="tablist">
            <li v-for="gr in groupeIndividuStore.listeGroupeIndividu" :key="gr.id"
                class="list-group-item list-group-item-action d-flex justify-content-between align-items-start"
                data-bs-toggle="list" @click="selectionner(gr)">
              {{ gr.libelle }}
              <span class="badge text-bg-primary rounded-pill">{{ gr.listeMembre.length }}</span>
            </li>
          </ul>
          <div v-if="groupeSelectionne" class="col-sm-12 col-md-6">
            <h4>{{groupeSelectionne.libelle}}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup lang="ts">

import { GroupeIndividu, useGroupeIndividuStore } from "@/store/groupe-individu-store";
import { onMounted, ref } from "vue";

const groupeIndividuStore = useGroupeIndividuStore()
const libelle = ref("");
const isCreationEnabled = ref(true);
const isLibelleValid = ref(false);
const groupeSelectionne = ref<GroupeIndividu|null>(null)

const evaluationValiditeLibelle = () => {
  isLibelleValid.value = libelle.value.length !== 0 && libelle.value.length <= 30;
}
const creerGroupe = async () => {
  isCreationEnabled.value = false
  if (isLibelleValid.value === true) {
    await groupeIndividuStore.creerGroupeIndividu(libelle.value);
    libelle.value = ""
  }
  isCreationEnabled.value = true
}

const selectionner = (gr : GroupeIndividu) =>{
  console.log(gr)
  groupeSelectionne.value = gr;
}

onMounted(async () => {
  await groupeIndividuStore.rechercherListe()
});
</script>


<style scoped lang="scss">

</style>